import React, { FC, useEffect } from 'react';
import AddCustomVideo from 'features/upload-custom-video/ui/AddCustomVideo';
import { Loader } from 'shared/ui';
import { SearchInput } from 'shared/ui/SearchInput';
import { TTheme } from 'widgets/choose-background/ui/types';
import { stockVideosStore } from 'features/list-stock-videos';
import { cn } from 'shared/lib';
import { useInView } from 'react-intersection-observer';

interface IProps {
  theme: TTheme;
  onChange: (value: string) => void;
  setCustomVideoBackgrounds: (value: string[]) => void;
  customVideoBackgrounds: string[];
  selectedBackground: string;
}

const VideosTab: FC<IProps> = ({
  theme,
  onChange,
  setCustomVideoBackgrounds,
  customVideoBackgrounds,
  selectedBackground
}) => {
  const {
    getStockVideos,
    changeQueryVideo,
    changePageVideo,
    stockVideos,
    isVideoLoaded
  } = stockVideosStore((state) => ({
    getStockVideos: state.getStockVideos,
    changeQueryVideo: state.changeQuery,
    changePageVideo: state.changePage,
    stockVideos: state.stockVideos,
    isVideoLoaded: state.isLoaded
  }));

  const { inView, ref } = useInView();
  useEffect(() => {
    getStockVideos();
  }, []);

  // @ts-ignore
  const handlePlayVideo = async (e: MouseEvent<HTMLVideoElement>) => {
    try {
      await (e.target as HTMLVideoElement).play();
    } catch (error) {}
  };

  // @ts-ignore
  const handlePauseVideo = (e: MouseEvent<HTMLVideoElement>) => {
    (e.target as HTMLVideoElement).pause();
  };

  const handleSearchVideo = (value: string) => {
    console.log('handleSearchVideo', value);
    if (value) {
      changeQueryVideo(value);
    } else {
      changeQueryVideo('nature');
    }
  };

  useEffect(() => {
    inView && changePageVideo();
  }, [inView]);

  return (
    <div>
      <div className={'ag-flex ag-items-center ag-justify-between ag-mb-6'}>
        <SearchInput onChange={handleSearchVideo} theme={theme} />
        <AddCustomVideo
          onUpload={(video) => {
            const value = `video;${video}`;
            setCustomVideoBackgrounds([value, ...customVideoBackgrounds]);
            onChange(value);
          }}
          inputId={'customImage'}
        />
      </div>
      <div className={'ag-grid ag-grid-cols-5 ag-gap-2'}>
        {customVideoBackgrounds.map((video) => (
          <video
            onMouseOver={handlePlayVideo}
            onMouseOut={handlePauseVideo}
            key={video}
            className={cn(
              'ag-aspect-square ag-rounded-lg ag-border ag-cursor-pointer ag-object-cover',
              selectedBackground === video
                ? 'ag-border-[3px] ag-border-primary-600'
                : theme === 'dark'
                ? 'ag-border-neutral-700'
                : 'ag-border-neutral-200'
            )}
            onClick={() => onChange(video)}
            src={video.replace('video;', '')}
          />
        ))}
        {stockVideos.map((video, index) => (
          <video
            onMouseOver={handlePlayVideo}
            onMouseOut={handlePauseVideo}
            src={video.video_files[0].link}
            key={video.id}
            ref={stockVideos.length - 1 === index ? ref : null}
            className={cn(
              'ag-aspect-square ag-rounded-lg ag-border ag-cursor-pointer ag-object-cover',
              selectedBackground ===
                `video;${
                  video.video_files.find((item) => item.width === 1920)?.link
                }`
                ? 'ag-border-[3px] ag-border-primary-600'
                : theme === 'dark'
                ? 'ag-border-neutral-700'
                : 'ag-border-neutral-200'
            )}
            onClick={() =>
              onChange(
                `video;${
                  video.video_files.find((item) => item.width === 1920)?.link
                }`
              )
            }
          />
        ))}

        {!isVideoLoaded && <Loader size={100} />}
      </div>
    </div>
  );
};

export default VideosTab;
