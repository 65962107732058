import { create } from 'zustand';
import { Conversation } from './conversationTypes';
import { toast } from 'react-toastify';
import { conversationApi } from '../api';
import { ITavusPersona } from 'entities/tavus/persona';
import { getErrorMessageV2, logger } from 'shared/lib';

type State = {
  currentConversation: Conversation | null;
  conversationLoading: boolean;
};

type Action = {
  startConversation: (
    currentPersona: ITavusPersona | null,
    key?: string
  ) => Promise<void>;
  endConversation: () => Promise<void>;
};

type Store = State & Action;

export const conversationStore = create<Store>((set, get) => ({
  currentConversation: null,
  conversationLoading: false,
  startConversation: async (currentPersona, key) => {
    if (!currentPersona) {
      toast.error('Please select a persona to start the conversation.');
      throw new Error('No persona selected.');
    }
    set({ conversationLoading: true });
    try {
      const conversation = await conversationApi.createConversation(
        currentPersona.id!,
        key
      );

      set({ currentConversation: conversation });
    } catch (error) {
      // const message = getErrorMessageV2(
      //   error,
      //   'Could not start the conversation.',
      //   true
      // );
      // toast.error(message);
      toast.error("Couldn't start the conversation. Please try again later.");
      throw error;
    } finally {
      set({ conversationLoading: false });
    }
  },
  endConversation: async () => {
    const conversationId = get().currentConversation?.conversation_id;

    if (!conversationId) {
      toast.error('No conversation to end.');
      return;
    }

    try {
      await conversationApi.endConversation(conversationId);

      set({ currentConversation: null });
    } catch (error) {
      toast.error("Couldn't end the conversation. Please try again later.");
    }
  }
}));
