import { AxiosResponse } from 'axios';
import { Conversation } from '../model';
import { tavusHttpService } from 'shared/api';
import { userStore } from 'entities/user';

const tavusConversationsEndpoint = '/conversation/';

export const conversationApi = {
  createConversation: async (personaId: string, key?: string) => {
    const { data }: AxiosResponse<Conversation> = await tavusHttpService.post(
      tavusConversationsEndpoint + `start/${personaId}`,
      {},
      {
        headers: {
          Authorization: userStore.getState().user?.internalKey || key
        }
      }
    );

    return data;
  },
  endConversation: async (conversationId: string) => {
    const data: AxiosResponse<any> = await tavusHttpService.post(
      `${tavusConversationsEndpoint}${conversationId}/end`
    );

    return data;
  }
};
