import React, { FC, useEffect } from 'react';
import { AddCustomImage } from 'features/upload-custom-image';
import { SearchInput } from 'shared/ui/SearchInput';
import { TTheme } from 'widgets/choose-background/ui/types';
import { useInView } from 'react-intersection-observer';
import { useStockImages } from 'features/list-stock-images';
import { Loader } from 'shared/ui';
import { cn } from 'shared/lib';

interface IProps {
  theme: TTheme;
  onChange: (value: string) => void;
  customImageBackgrounds: string[];
  setCustomImageBackgrounds: (backgrounds: string[]) => void;
  selectedBackground: string;
}

export const ImagesTab: FC<IProps> = ({
  theme,
  selectedBackground,
  customImageBackgrounds,
  setCustomImageBackgrounds,
  onChange
}) => {
  const {
    images,
    changeQuery,
    changePage,
    loaded: isImageLoaded
  } = useStockImages();

  const { ref, inView } = useInView();

  const handleSearch = (value: string) => {
    console.log('handleSearch', value);
    if (value) {
      changeQuery(value);
    } else {
      changeQuery('video background');
    }
  };

  useEffect(() => {
    inView && changePage();
  }, [inView]);

  return (
    <div>
      <div className={'ag-flex ag-items-center ag-justify-between ag-mb-6'}>
        <SearchInput onChange={handleSearch} theme={theme} />
        <AddCustomImage
          onUpload={(image) => {
            const value = `image;url(${image})`;
            onChange(value);
            setCustomImageBackgrounds([value, ...customImageBackgrounds]);
          }}
          inputId={'customImage'}
        />
      </div>

      <div className={'ag-grid ag-grid-cols-5 ag-gap-2'}>
        {customImageBackgrounds.map((image) => (
          <div
            key={image}
            className={cn(
              'ag-max-w-24 ag-aspect-square ag-h-24 ag-rounded-lg ag-border ag-cursor-pointer',
              selectedBackground === image
                ? 'ag-border-[3px] ag-border-primary-600'
                : theme === 'dark'
                ? 'ag-border-neutral-700'
                : 'ag-border-neutral-200'
            )}
            onClick={() => {
              onChange(image);
            }}
            style={{
              background: image.replace('image;', ''),
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}
          />
        ))}

        {images.map((image, index) => (
          <div
            ref={images.length - 1 === index ? ref : null}
            className={cn(
              'ag-max-w-24 ag-aspect-square ag-rounded-lg ag-border ag-cursor-pointer ag-bg-contain',
              selectedBackground === `image;url(${image.regular})`
                ? 'ag-border-[3px] ag-border-primary-600'
                : theme === 'dark'
                ? 'ag-border-neutral-700'
                : 'ag-border-neutral-200'
            )}
            onClick={() => onChange(`image;url(${image.regular})`)}
            style={{
              background: `url(${image.thumb})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}
            key={image.regular}
          />
        ))}

        {!isImageLoaded && <Loader size={100} />}
      </div>
    </div>
  );
};
