import { create } from 'zustand';
import { tavusPersonaApi } from '../api';
import { toast } from 'react-toastify';
import {
  ICreateTavusPersonaReq,
  ICreateTavusPersonaRes,
  ITavusPersona,
  ITavusPersonaFormik
} from './tavusPersonaTypes';
import { showError } from 'shared/model';
import { getErrorMessageV2 } from 'shared/lib';
import { userStore } from 'entities/user';

export const tavusInitialPersona: ITavusPersonaFormik = {
  name: '',
  defaultReplicaId: '',
  context: '',
  systemPrompt: '',
  model: 'llama',
  customBackground: '',
  customGreeting: ''
};

type State = {
  isPersonaCreating: boolean;
  currentPersona: ITavusPersona | null;
  tavusPersonas: ITavusPersona[];
  tavusPersonasLoading: boolean;
  personaEmbedModal: string | null;
  isPersonaDeleting: boolean;
};
type Actions = {
  addPersona: (
    data: ICreateTavusPersonaReq
  ) => Promise<ICreateTavusPersonaRes | undefined>;
  fetchPersonas: () => Promise<void>;
  fetchOne: (
    id: string,
    showErrorToast?: boolean
  ) => Promise<ITavusPersona | undefined>;
  setCurrentPersona: (persona: ITavusPersona | null) => void;
  togglePersonaEmbedModal: (personaId: string | null) => void;
  deletePersona: (id: string) => Promise<void>;
  updatePersona: (
    id: string,
    personaData: ICreateTavusPersonaReq
  ) => Promise<void>;
};

type Store = State & Actions;

export const tavusPersonaStore = create<Store>((set, get) => ({
  currentPersona: null,
  tavusPersonas: [],
  tavusPersonasLoading: false,
  personaEmbedModal: null,
  isPersonaCreating: false,
  isPersonaDeleting: false,
  addPersona: async (personaData) => {
    set({ isPersonaCreating: true });
    try {
      const data = await tavusPersonaApi.create(personaData);
      await get().fetchPersonas();
      return data;
    } catch (error) {
      const message = getErrorMessageV2(
        error,
        'There was a problem when we tried to create a persona. Please try again later.'
      );
      showError(message);
    } finally {
      set({ isPersonaCreating: false });
    }
  },
  updatePersona: async (id, personaData) => {
    set({ isPersonaCreating: true });
    try {
      await tavusPersonaApi.update(id, personaData);
      await get().fetchPersonas();
    } catch (error) {
      const message = getErrorMessageV2(
        error,
        'There was a problem when we tried to change a persona. Please try again later.'
      );
      showError(message);
    } finally {
      set({ isPersonaCreating: false });
    }
  },
  deletePersona: async (id) => {
    set({ isPersonaDeleting: true });
    try {
      await tavusPersonaApi.delete(id);
      const tavusPersonas = [...get().tavusPersonas];
      const indexToDelete = tavusPersonas.findIndex((p) => p.id === id);
      tavusPersonas.splice(indexToDelete, 1);

      set({ tavusPersonas });
    } catch (error) {
      const message = getErrorMessageV2(
        error,
        'There was a problem when we tried to delete a persona. Please try again later.'
      );
      showError(message);
    } finally {
      set({ isPersonaDeleting: false });
    }
  },
  setCurrentPersona: (persona) => {
    set({ currentPersona: persona });
  },
  togglePersonaEmbedModal: (personaId) => {
    set({ personaEmbedModal: personaId });
  },
  fetchPersonas: async () => {
    set({ tavusPersonasLoading: true });
    try {
      const tavusPersonas = await tavusPersonaApi.fetchAll();

      set({ tavusPersonas });
    } catch (error) {
      toast.error(
        'There was a problem when we tried to get personas list. Please try again later.'
      );
    } finally {
      set({ tavusPersonasLoading: false });
    }
  },
  fetchOne: async (id: string, showErrorToast = true) => {
    set({ tavusPersonasLoading: true });
    try {
      return await tavusPersonaApi.fetch(id);
    } catch (error) {
      if (showErrorToast) {
        toast.error(
          'There was a problem when we tried to get persona details. Please try again later.'
        );
      }
    } finally {
      set({ tavusPersonasLoading: false });
    }
  }
}));
