import { Navigate, useNavigate, useParams } from 'react-router';
import { useEffect } from 'react';
import {
  MAIN_ROUTE,
  Loader,
  LOGIN_ROUTE,
  useQueryParams,
  showError,
  logger
} from 'shared';
import { videoCallStore } from 'features/livekit-call/';
import { useAuthStore } from 'stores/useAuthStore';
import { Layout } from 'widgets/layout';
import { creditStore } from 'entities/credit';
import { userStore } from 'entities/user';
import { conversationStore } from 'entities/tavus/conversation/model/conversationStore';
import { tavusPersonaStore } from 'entities/tavus/persona';
import { TavusConference } from './TavusConference';

export const TavusCall = () => {
  const {
    startConversation,
    currentConversation,
    conversationLoading,
    endConversation
  } = conversationStore();
  const { currentPersona } = tavusPersonaStore();
  const { user } = userStore();
  const { isChatAvailable } = creditStore();

  const nav = useNavigate();

  useEffect(() => {
    if (user && isChatAvailable) {
      startConversation(currentPersona).catch(() => {
        nav(MAIN_ROUTE);
      });
    }
  }, [user, isChatAvailable]);

  return (
    <>
      {conversationLoading ? (
        <div className="ag-w-full ag-h-screen ag-bg-neutral-900 ag-flex ag-items-center ag-justify-center   ">
          <Loader size={96} />
        </div>
      ) : (
        <>{currentConversation ? <TavusConference /> : null}</>
      )}
    </>
  );
};
